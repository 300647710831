import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import 'i18n';
import config from 'config';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';

import CMP_VC_AUTHENTICATOR from 'components/cmp_vc_authenticator/cmp_vc_authenticator';

import './crd_external_wallet.css';

/**
 * @param {{
 *      credential: {
 *          id: String,
 *          display_share_to_midy?: 'YES'|'NO'
 *      }
 * }} props 
 */
export default function CRD_EXTERNAL_WALLET({ credential }) {
    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_error_message, set_error_message ] = useState(null);

    /** @type {ReturnType<typeof useState<{ requestId: String, url: String, expiry: Number, callback_id: String }>>} */
    const [ var_entra_issuance_request, set_entra_issuance_request ] = useState(null);
    const [ var_cmp_vc_authenticator_display, set_cmp_vc_authenticator_display ] = useState(false);
    const [ var_cmp_vc_authenticator_processing, set_cmp_vc_authenticator_processing] = useState(false);
    /** @type {ReturnType<typeof useState<{ title: String, instruction_line: String, open_button_text: String }>>} */
    const [ var_cmp_vc_authenticator_text, set_cmp_vc_authenticator_text ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (!var_cmp_vc_authenticator_display && (var_focuselement?.id === 'btn_share_to_credivera_mobile_app' || var_focuselement?.id === 'btn_share_to_authenticator')) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_cmp_vc_authenticator_display]);

    useEffect(() => {
        // Generate new QR after expiry
        if (var_cmp_vc_authenticator_display && var_entra_issuance_request?.expiry) {
            let previous_QR_expiry = (var_entra_issuance_request?.expiry - Math.floor(Date.now() / 1000)) * 1000;
            let interval = setInterval(async () => {
                set_cmp_vc_authenticator_processing(true);
                await create_entra_credential_offer();
                set_cmp_vc_authenticator_processing(false);
            }, previous_QR_expiry);
            return () => clearInterval(interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_cmp_vc_authenticator_display, var_entra_issuance_request?.expiry]);

    //  async functions ------------------------------------------------------------------------------------------------
    async function create_entra_credential_offer() {
        set_error_message(null);

        try {
            let entra_issuance_request = await API_post_credential_issuance();
            set_entra_issuance_request(entra_issuance_request);
            set_cmp_vc_authenticator_display(true);
        } catch (e) {
            console.error(e);
            set_error_message(t('There was a problem loading.  Please try again later'));
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------
    /**
     * @returns {Promise<{ requestId: String, url: String, expiry: Number, callback_id: String }>}
     */
    function API_post_credential_issuance() {
        return API.post('credentials', '/post-credential-issuance/', {
            body: { individualcredential_id: credential.id }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------
    async function onClick_add_to_credivera() {
        set_focuselement(document.activeElement);
        set_processing(true);
        set_cmp_vc_authenticator_text({ 
            title: t('Share to Credivera mobile app'), 
            instruction_line: t('Scan the QR code below with your mobile device. If viewing this on mobile, click the "Open Credivera mobile app" button.'),
            open_button_text: t('Open Credivera mobile app')
        });
        await create_entra_credential_offer();
        set_processing(false);
    }

    async function onClick_add_to_authenticator() {
        set_focuselement(document.activeElement);
        set_processing(true);
        set_cmp_vc_authenticator_text({ 
            title: t('Share to Authenticator'), 
            instruction_line: t('Scan the QR code below with your mobile device. If viewing this on mobile, click the "Open Microsoft Authenticator" button.'),
            open_button_text: t('Open Microsoft Authenticator')
        });
        await create_entra_credential_offer();
        set_processing(false);
    }

    /**
     * @param {Boolean} error 
     */
    function onClose_cmp_vc_authenticator(error) {
        set_cmp_vc_authenticator_display(false);
        set_cmp_vc_authenticator_processing(false);
        set_entra_issuance_request(null);
        if (error) set_error_message(t('An error occurred while issuing this credential to your wallet. Please try again.'));
    }

    // RENDER APP ======================================================================================================
    return (
        <div id='crd_external_wallet' className='card rounded-lg shadow-sm'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div id='hdr_credexternal_wallet' className='text--xl-medium'>{t('External wallet')}</div>
                </div>
            </div>

            {var_error_message && 
                <Message error icon={<Icon name='error' className='icon' />} content={var_error_message} />
            }

            <div className='card__content'>
                <div className='text--sm-regular'>{t('Add this credential to an external wallet, like the Credivera mobile app or Microsoft Authenticator.')}</div>
                <div className='button_group'>
                    <Button className='primary' id='btn_share_to_credivera_mobile_app' onClick={onClick_add_to_credivera}>{t('Add to Credivera mobile app')}</Button>
                    <Button className='secondary' id='btn_share_to_authenticator' onClick={onClick_add_to_authenticator}>{t('Add to Microsoft Authenticator')}</Button>
                </div>
                <div className='hr'/>
                <div className='text--md-medium'>{t('Get the Credivera mobile app')}</div>
                <div className='text--sm-regular'>{t('The Credivera mobile app lets you securely store, manage, and share your digital credentials, keeping your data private and under your control.')}</div>
                <div className='badge_group'>
                    <CMP_STORE_BADGE store='apple' app_url={`https://apps.apple.com/ca/app/credivera/id6618157943?l=${auth.language.i18n.split('-')[0]}`}/>
                    <CMP_STORE_BADGE store='google' app_url={`https://play.google.com/store/apps/details?id=com.credivera.wallet&hl=${auth.language.i18n}`}/>
                </div>
            </div>

            <CMP_VC_AUTHENTICATOR
                display={var_cmp_vc_authenticator_display}
                title={var_cmp_vc_authenticator_text?.title}
                instruction_line={var_cmp_vc_authenticator_text?.instruction_line}
                open_button_text={var_cmp_vc_authenticator_text?.open_button_text}
                entra_issuance_request={var_entra_issuance_request}
                processing={var_cmp_vc_authenticator_processing}
                onClose={onClose_cmp_vc_authenticator}
            />

            <Processing display={var_processing} processingtext={t('Processing')} />
        </div>
    );
}

/**
 * 
 * @param {{
 *      store: 'apple'|'google',
 *      app_url: String
 * }} props
 */
function CMP_STORE_BADGE({ store, app_url }) {
    const { t } = useTranslation('public');

    return (
        <Button className='store_badge' onClick={() => window.open(app_url)}>
            {store === 'apple' ? 
                <img 
                    className='store_badge__img_apple' 
                    src={config.language[auth.language.i18n.split('-')[0]].apple_app_store_badge} 
                    alt={t('store badge')} />
            : 
                <img 
                    className='store_badge__img_google' 
                    src={config.language[auth.language.i18n.split('-')[0]].google_play_store_badge} 
                    alt={t('store badge')} />
            }
        </Button>
    );
}