import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import Icon from 'components/cmp_icon';
import Logo from 'components/cmp_logo';
import CMP_CREDENTIAL_STATUS_ICON from 'components/cmp_credential_status_icon/cmp_credential_status_icon';
import datelib from 'libs/date-lib';
import config from 'config';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './cmp_certificate.css';



function CMP_CERTIFICATE({ display_type = 'page', certificate, verifiable_credential, tab, ready = true, linkstatus = 'ACTIVE', onClick_document, focus_element, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (focus_element && activetab === 'PREVIEW') {
            document.getElementById(focus_element)?.focus();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_proof() {
        tab('PROOF');
    }

    function onClick_renewal() {
        certificate.website.toLowerCase().startsWith('https://') ? window.open(certificate.website) : window.open('https://' + certificate.website);
    }

    function onKeyDown_renewal(e) {
        if (e.key === 'Enter') {
            onClick_renewal();
        }
    }

    function onKeyDown_external_link(e) {
        if (e.key === 'Enter') {
            window.open(certificate.external_link);
        }
    }

    // RENDER ==========================================================================================================
    return (
        <div className={`cmp_certificate shadow-xs certificate__${display_type}`}>
                {ready && linkstatus === 'ACTIVE' &&
                    <>
                        {(certificate.issuer_logo && certificate.issuer_logo !== '') &&
                            <div className='certificate__header'>
                                <Logo targetid={certificate.issuer_id} classification='logo' filename={certificate.issuer_logo} backup_text={certificate.issuer_name} alt={t('Issued by') + ' ' + certificate.issuer_name} />
                            </div>
                        }

                        <div className='certificate__body' style={{ backgroundColor: certificate.background_color, color: certificate.text_color }}>

                            <div className={'badge__status text--sm-medium ' +
                                (certificate.verification_status === 'INVALID' ? 'badge__status--red'
                                    : certificate.verification_status === 'PENDING' ? 'badge__status--yellow'
                                        : certificate.verification_status === 'IN PROGRESS' ? 'badge__status--yellow'
                                            : certificate.is_self_verified === 'YES' ? 'badge__status--blue'
                                                : 'badge__status--green')}>
                                <CMP_CREDENTIAL_STATUS_ICON confirmation_status={certificate.confirmation_status} is_self_verified={certificate.is_self_verified} />
                                {certificate.confirmation_status &&
                                    certificate.confirmation_status === 'PENDING'
                                        ? t('Pending Verification')
                                        : t(certificate.confirmation_status.substring(0, 1).toUpperCase() + certificate.confirmation_status.substring(1).toLowerCase())
                                }
                            </div>

                            <div className='certificate__body__name' style={{ color: certificate.header_color }}>
                                <div className='display--md-bold' >{certificate.credential_name}</div>
                            </div>

                            <div className='certificate__body__holder' style={{ color: certificate.header_color }}>
                                {certificate.issued &&
                                    <div className='text--sm-regular'>
                                        {t('Issued on')}
                                        <span className='text--sm-medium'>
                                            {Number.isNaN(Number(certificate.issued))
                                                ? certificate.issued
                                                : datelib.epoch_to_date_string(certificate.issued)}
                                        </span>
                                        {t('to')}
                                    </div>
                                }
                                <div className='display--sm-medium' >{certificate.individual_name}</div>
                            </div>

                            {(certificate.additional_fields || certificate.credit_value) &&
                                <div className='certificate__additional_fields'>
                                    {certificate.additional_fields && certificate.additional_fields.sort((a, b) => a.order - b.order).map((item, index) =>
                                        <div className='certificate__body__datagroup' key={'additional_field_' + index}>
                                            <div className='text--sm-medium'>{item.translate_label ? t(item.label) : item.label}:</div>
                                            <div className='text--sm-regular'>
                                                {item.datatype === 'date'
                                                    ? datelib.epoch_to_date_string(item.value)
                                                    : item.datatype === 'datetime'
                                                        ? datelib.epoch_to_datetime_string(item.value, true)
                                                        : item.value
                                                }
                                            </div>
                                        </div>
                                    )}
                                    {certificate.credit_value &&
                                        <div className='certificate__body__datagroup'>
                                            <div className='text--sm-medium'>{t('Credit value')}:</div>
                                            <div className='text--sm-regular'>
                                                {certificate.credit_value?.toFixed(2)}
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {certificate.pii !== undefined &&
                                <div className='certificate__body__datagroup'>
                                    <div className='text--sm-medium'>{t('PII')}:</div>
                                    <div className='text--sm-regular'>
                                        {certificate.pii}
                                    </div>
                                </div>
                            }

                            <div className='certificate__body__verification'>
                                <div className='certificate__body__datagroup'>
                                    <div className='text--sm-medium'>{t('Issuer')}:</div>
                                    {(certificate.website === null || !certificate.website)
                                        ?
                                        <div className='text--sm-regular'>{certificate.issuer_name}</div>
                                        :
                                        <div className='text--sm-regular certificate__link' role='link' onKeyDown={onKeyDown_renewal} onClick={onClick_renewal} tabIndex='0' style={{ color: certificate.link_color }}>
                                            {certificate.issuer_name}
                                        </div>
                                    }
                                </div>
                                {(certificate.is_self_verified !== 'YES' && !(certificate.has_prequalifier === 'YES' && certificate.valid_on_completion === 'YES') && certificate.credits_required_for_issuance === 'NO') &&
                                    <>
                                        {certificate.verification_status !== 'PENDING' &&
                                            <div className='certificate__body__datagroup'>
                                                <div className='text--sm-medium'>{t('Verification issued by')}:</div>
                                                <div className='text--sm-regular'>{certificate.verifying_organization_name}</div>
                                            </div>
                                        }
                                        {certificate.issued &&
                                            <div className='certificate__body__datagroup'>
                                                <div className='text--sm-medium'>{t('Valid from')}:</div>
                                                <div className='text--sm-regular date'>
                                                    {certificate.issued === null
                                                        ? '-'
                                                        : Number.isNaN(Number(certificate.issued))
                                                            ? certificate.issued
                                                            : datelib.epoch_to_date_string(certificate.issued)
                                                    }
                                                    {certificate.verification_status === 'PENDING' && certificate.issued && ' ' + t('(pending verification)')}
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                                {certificate.expires &&
                                    <div className='certificate__body__datagroup'>
                                        <div className='text--sm-medium'>
                                            {certificate.is_self_verified === 'YES'
                                                ? t('Expires')
                                                : t('Valid until')
                                            }
                                        :</div>
                                        <div className='text--sm-regular'>
                                            {Number.isNaN(Number(certificate.expires))
                                                ? certificate.expires
                                                : datelib.epoch_to_date_string(certificate.expires)
                                            }
                                            {certificate.verification_status === 'PENDING' && ' ' + t('(pending verification)')}
                                        </div>
                                    </div>
                                }
                                {(certificate.verification_status !== 'PENDING' && (certificate.verification_id || certificate.verified_transaction_id)) &&
                                    <div className='certificate__body__datagroup'>
                                        <div className='text--sm-medium'>
                                            {(certificate.is_self_verified === 'YES' || (certificate.has_prequalifier === 'YES' && certificate.valid_on_completion === 'YES'))
                                                ? t('Transaction ID')
                                                : t('Verification ID')
                                            }
                                        :</div>
                                        <div className='text--sm-regular'>{certificate.verification_id ? certificate.verification_id : certificate.verified_transaction_id}</div>
                                    </div>
                                }
                            </div>


                            {verifiable_credential && verifiable_credential.vc &&
                                <div className='certificate__body__proof'>
                                    <Button className='secondary' onClick={onClick_proof}>{t('See proof')}</Button>
                                </div>
                            }

                            {((certificate.credential_document !== '' && certificate.credential_document !== undefined && certificate.credential_document !== null && certificate.credential_document !== 'No file selected...') || certificate.submittedfilename) &&
                                <div className='certificate__body__document'>
                                    <div className='certificate__body__document__item'>
                                        <Icon name='document' className='' alt={t('Document')}/>
                                        <Button className='tertiary supporting-doc' disabled={!onClick_document} onClick={onClick_document} style={{ color: certificate.link_color }}>{t('Supporting document')}</Button>
                                    </div>
                                    <div className='text--xs-regular'>{t('Supporting documents have been provided by the holder of the credential and may not have been verified by the credential issuer.')}</div>
                                </div>
                            }

                        </div>

                        {certificate?.prequalifiers?.length > 0 &&
                            <div className='prequalifiers_wrapper'>
                                <div className='prequalifiers_title text--md-medium'>{t('Pre-qualifications')}</div>
                                {certificate.prequalifiers.map(item => {
                                    switch (item.prequalifier_type) {
                                        case 'PREVIEW':
                                            return render_preview(item);
                                        case 'ATTESTATION':
                                            return render_attestation(item);
                                        case 'CREDENTIAL':
                                            return render_credential(item);
                                        case 'CREDENTIAL-CREDIT':
                                            return render_credit_credential(item);
                                        case 'DOCUMENT':
                                            return render_document(item);
                                        case 'SURVEY':
                                            return render_survey(item);
                                        default:
                                            throw new Error('Not implemented');
                                    }
                                })}
                            </div>
                        }


                        {(certificate.credential_description || certificate.credential_skills || certificate.credential_logo || certificate.external_link) &&
                            <div className='certificate__details'>
                                {(certificate.credential_logo !== '' && certificate.credential_logo !== null && certificate.credential_logo !== 'No file selected...') &&
                                    <div className='certificate__details__logo'>
                                        <Logo targetid={certificate.credential_id} classification='course' filename={certificate.credential_logo} alt={t('certificate logo')} />
                                    </div>
                                }
                                {(certificate.credential_description || certificate.credential_skills || certificate.external_link) &&
                                    <div className='certificate__details__text' style={{ color: certificate.description_color }}>
                                        {certificate.credential_description !== '' && certificate.credential_description !== null &&
                                            <>
                                                <div className='text--md-medium'>{t('Description')}</div>
                                                <div className='text--sm-regular'>{certificate.credential_description.replace(/(<([^>]+)>)|\r\n|\n|\r|\\n|\\r/gi, '')}</div>
                                            </>
                                        }
                                        {(!!certificate.external_link && !!certificate.external_link_anchor_text) &&
                                            <>
                                                <div className='text--md-medium'>{t('More information')}</div>
                                                <div className='text--sm-regular external__link' role='link' onKeyDown={onKeyDown_external_link} onClick={() => window.open(certificate.external_link)} tabIndex='0' style={{ color: certificate.link_color }}>
                                                    {certificate.external_link_anchor_text}
                                                </div>
                                            </>
                                        }
                                        {certificate.credential_skills !== '' && certificate.credential_skills !== null &&
                                            <>
                                                <div className='text--md-medium'>{t('Skills')}</div>
                                                <div className='text--sm-regular'>{certificate.credential_skills}</div>
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                        }

                        <div className='certificate__footer'>
                            <div className='text--xs-regular'>{t('Powered by')}</div>
                            <img src={`${config.images.url}${config.images.assets.Credivera_Horizontal_Blue}${config.images.stage}`} alt={t('Credivera logo')}/>
                        </div>
                    </>
                }
        </div>
    );

    function render_preview(item) {
        return (
            <div key={item.prequalifier_id} className='prequalifier_item'>
                <div>
                    <div><Icon name='checkmark' className='prequalifiers_icon color--green-500' alt={t('status icon')} /></div>
                    <div className='prequalifier_text'>
                        <div className='text--sm-regular'>{item.short_description}</div>
                        <div className='text--xs-regular'>
                            {`${t('Completed')} ${t('YYYY-MM-DD 10:00 AM')}`}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function render_attestation(item) {
        return (
            <div key={item.individualprequalifier_id} className='prequalifier_item'>
                <div>
                    {item.status === 'VALID'
                        ? <div><Icon name='checkmark' className='prequalifiers_icon color--green-500' alt={t('status icon')} /></div>
                        : <div><Icon name='warning_circle' className='prequalifiers_icon warning-icon--yellow' alt={t('status icon')} /></div>
                    }
                    <div className='prequalifier_text'>
                        <div className='text--sm-regular'>{item.short_description}</div>
                        {item.status === 'VALID' &&
                            <div className='text--xs-regular'>
                                {`${t('Completed')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}${item.expires_date ? ' | ' + t('Expires') + ' ' + datelib.epoch_to_date_string(item.expires_date) : ''}`}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    function render_credential(item) {
        return (
            <div key={item.individualprequalifier_id} className='prequalifier_item credential'>
                <div>
                    {item.status === 'VALID'
                        ? <div><Icon name='checkmark' className='prequalifiers_icon color--green-500' alt={t('status icon')} /></div>
                        : item.status === 'PENDING'
                        ? <div><Icon name='eye_circle' className='prequalifiers_icon color--yellow-500' alt={t('status icon')} /></div>
                        : <div><Icon name='warning_circle' className='prequalifiers_icon warning-icon--yellow' alt={t('status icon')} /></div>
                    }
                    <div className='prequalifier_text'>
                        {(item.issuer_logo && item.issuer_logo !== '') &&
                            <div className='prequalifier_credential_issuer_logo'>
                                <Logo targetid={item.issuer_id} classification='logo' filename={item.issuer_logo} backup_text={item.issuer_name} alt={t('Issued by') + ' ' + certificate.issuer_name} />
                            </div>
                        }
                        {item.status === 'VALID'
                            ?
                            <>
                                <div className='text--sm-regular'>{item.credential_name}</div>
                                <div className='text--xs-regular'>
                                    {item.additional_text && item.additional_text === 'Verifiable credential'
                                        ? `${t('Verifiable credential submitted')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}`
                                        : <>
                                            {item.issued_date ? `${t('Issued')}: ${datelib.epoch_to_date_string(item.issued_date, false)}`  : ``}
                                            {item.expires_date  ? `\u00A0 ${t('Expires')}: ${datelib.epoch_to_date_string(item.expires_date , false)}`  : ``}
                                        </>
                                    }
                                </div>
                            </>
                            : <div className='text--sm-regular'>{item.short_description}</div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    function render_credit_credential(item) {
        return (
            <div key={item.prequalifier_id} className='prequalifier_item'>
                <div>
                    {item.status === 'VALID'
                        ? <div><Icon name='checkmark' className='prequalifiers_icon color--green-500' alt={t('status icon')} /></div>
                        : item.status === 'PENDING'
                        ? <div><Icon name='eye_circle' className='prequalifiers_icon color--yellow-500' alt={t('status icon')} /></div>
                        : <div><Icon name='warning_circle' className='prequalifiers_icon warning-icon--yellow' alt={t('status icon')} /></div>
                    }
                    <div className='prequalifier_text'>
                        <div className='text--sm-regular'>{item.short_description}</div>
                        <div className={'badge text--sm-medium' + (item.credits_obtained >= item.credits_required ? ' badge--green' : ' badge--yellow')}>
                            {`${item.credits_obtained}/${item.credits_required} ${item.credits_required === 1 ? t('credit') : t('credits')}`}
                        </div>

                        {item.submitted_credentials.map(submitted =>
                            <div key={submitted.individual_prequalifier_credential_id} className='prequalifier_credential_detail text--xs-regular'>
                                {`${submitted.credential_name} (${submitted.status === 'EXPIRED'? t('Expired') : `${submitted.credit_value} ${submitted.credit_value === 1 ? t('credit') : t('credits')}`}) ${submitted.issued_date ? `${t('Issued')}: ${datelib.epoch_to_date_string(submitted.issued_date, false)}` : ``} ${submitted.expires_date ? `\u00A0 ${t('Expires')}: ${datelib.epoch_to_date_string(submitted.expires_date, false)}` : ``}`}
                            </div>
                        )}

                    </div>
                </div>
            </div>
        );
    }

    function render_document(item) {
        return (
            <div key={item.individualprequalifier_id} className='prequalifier_item'>
                <div>
                    {item.status === 'VALID'
                        ? <div><Icon name='checkmark' className='prequalifiers_icon color--green-500' alt={t('status icon')} /></div>
                        : <div><Icon name='warning_circle' className='prequalifiers_icon warning-icon--yellow' alt={t('status icon')} /></div>
                    }
                    <div className='prequalifier_text'>
                        <div className='text--sm-regular'>{item.short_description}</div>
                        {item.status === 'VALID' &&
                            <div className='text--xs-regular'>
                                {`${t('Completed')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}${item.expires_date ? ' | ' + t('Expires') + ' ' + datelib.epoch_to_date_string(item.expires_date) : ''}`}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    function render_survey(item) {
        return (
            <div key={item.individualprequalifier_id} className='prequalifier_item'>
                <div>
                    {item.status === 'VALID'
                        ? <div><Icon name='checkmark' className='prequalifiers_icon color--green-500' alt={t('status icon')} /></div>
                        : <div><Icon name='warning_circle' className='prequalifiers_icon warning-icon--yellow' alt={t('status icon')} /></div>
                    }
                    <div className='prequalifier_text'>
                        <div className='text--sm-regular'>{item.short_description}</div>
                        {item.status === 'VALID' &&
                            <div className='text--xs-regular'>
                                {`${t('Completed')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}`}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

CMP_CERTIFICATE.propTypes = {
    display_type: propTypes.oneOf([ 'page', 'modal', 'inline' ]),
    certificate: propTypes.object.isRequired,
    ready: propTypes.bool,
    linkstatus: propTypes.string,
    onClick_document: propTypes.func
};

export default CMP_CERTIFICATE;