import React, { useEffect, useState, useCallback } from 'react';

import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';

import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import API from 'libs/api-lib';


import './demo.css';

export default function DEMO() {

    //  variable declarations ------------------------------------------------------------------------------------------
    
    const { t } = useTranslation('public');

    const [ var_issuance_request, set_issuance_request ] = useState(null);
    const [ var_error, set_error ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        create_issuance_request();
    }, []);


    //  async functions ------------------------------------------------------------------------------------------------

    async function create_issuance_request() {
        set_processing(true);
        set_issuance_request(null);
        set_error(false);
        try {
            // throw new Error('TEST');
            let issuance_request = await API_post_issuance_request();
            set_issuance_request(issuance_request);
        } catch (e) {
            set_error(true);
        } finally {
            set_processing(false)
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_issuance_request() {
        return API.post('credentials', `/demo-issuance`);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_openLink() {
        window.open(var_issuance_request.url);
    }

    // RENDER APP ======================================================================================================

    return (
        <div id='crd__issuance'>
            <div className='issuance__wrapper rounded-lg shadow-sm'>
                <div className='issuance__title display--sm-medium'>Credivera Demo Credential</div>
                <div className='issuance__description text--sm-regular'>Please scan the below QR Code with the Credivera Mobile App</div>
                {var_error ?
                    <Message
                        error
                        icon={<Icon name='error' className='icon' />}
                        header={t('An unexpected error occurred. Please try again.')}
                    />
                :
                <div className='qrcode_body'>
                    <div className='qrcode_wrapper'>
                        {var_issuance_request ?
                            <QRCodeSVG
                                className='qrcode'
                                id='canvasQRcode'
                                value={var_issuance_request.url}
                                size={200}
                                level='H'
                            />
                        :
                            <div style={{width: 200, height: 200}}>
                                <Processing display={var_processing} style={{width: 200, height: 200}}/>
                            </div>
                        }
                    </div>
                </div>}
                {!var_processing && <Button className='tertiary' onClick={create_issuance_request}>{t('Generate a new QR Code')}</Button>}
                {/Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
                // Not sure if that is the complete list, but should cover 99% or the cases?
                <Button className='secondary' onClick={onClick_openLink}>{t(`Can't scan QR code?`)}</Button>
                }
            </div>
        </div>
    );
};
