import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Modal } from 'semantic-ui-react';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import datelib from 'libs/date-lib';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './mdl_prequalifiers_details.css';

import CMP_MDL_CERTIFICATE from 'components/cmp_mdl_certificate/cmp_mdl_certificate';

export default function MDL_PREQUALIFIERS_DETAILS({ individual_credential_id, is_open, onClose, holder_name }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_prequalifiers, set_prequalifiers ] = useState([]);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_certificate_open, set_certificate_open ] = useState(false);
    const [ var_selected_credential_id, set_selected_credential_id ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){
            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (is_open) {
            populate_prequalifiers();
            set_modal(document.querySelector('#mdl_prequalifiers_details'));
        } else {
            set_prequalifiers([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (!var_certificate_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('mdl_content').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_certificate_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_prequalifiers() {
        set_prequalifiers(await API_get_credential_prequalifiers());
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_prequalifiers() {
        return API.get('credentials', '/get-credential-prequalifiers/' + individual_credential_id, { queryStringParameters: { tz: datelib.timezone }});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_submitted_credential(additional_text, credential_id) {
        if (additional_text !== 'Verifiable credential') {
            set_focuselement(document.activeElement);
            set_selected_credential_id(credential_id);
            set_certificate_open(true);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='mdl_prequalifiers_details'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_holder_name'
        >
            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium' id='hdr_holder_name'>{holder_name}</div>
                </div>
            </div>

            <div className='modal__content' id='mdl_content' tabIndex='0'>
                <Table loading={false} ready={var_prequalifiers.length > 0}
                        loadingerror={false} lockcolumns={0}
                        refresh={false} totalrows={var_prequalifiers.length}
                        populateconfig={{ ...default_populateconfig, sortby: '' }}
                        populatefilterfunction={() => null}
                        downloadname={t('Pre-qualifiers')}
                        downloadfunction={() => null}
                        onChange={() => null}
                        hide_tablecontrols={true}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='status' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='prequalifier_type' datatype='text' filtertype='option'>{t('PRE-QUALIFIER')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_prequalifiers.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell className='cell__icon cell--center'>
                                    {item.status === 'VALID'
                                        ? <Icon name='checkmark' className='prequalifiers_icon color--green-500' alt={t('Valid pre-qualifier')} />
                                        : item.status === 'PENDING'
                                        ? <Icon name='eye_circle' className='prequalifiers_icon color--yellow-500' alt={t('Pending pre-qualifier')} />
                                        : <Icon name='warning_circle' className='prequalifiers_icon warning-icon--yellow' alt={t('Incomplete pre-qualifier')} />
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    {
                                        item.prequalifier_type === 'ATTESTATION'
                                        ? render_attestation(item)
                                        : item.prequalifier_type === 'CREDENTIAL'
                                        ? render_credential(item)
                                        : item.prequalifier_type === 'CREDENTIAL-CREDIT'
                                        ? render_credit_credential(item)
                                        : item.prequalifier_type === 'DOCUMENT'
                                        ? render_document(item)
                                        : item.prequalifier_type === 'SURVEY'
                                        ? render_survey(item)
                                        : null
                                    }
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </div>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>

            <CMP_MDL_CERTIFICATE
                credential_id={var_selected_credential_id}
                verifiable_credential={null}
                is_open={var_certificate_open}
                onClose={() => set_certificate_open(false)}
            />

        </Modal>
    )

    function render_attestation(item) {
        return (
            <div key={item.individualprequalifier_id} className='prequalifier'>
                <div className='text--sm-medium'>{t('Attestation')}</div>
                <div className='prequalifier_text'>
                    <div className='text--sm-regular description'>{item.short_description}</div>
                    {item.status === 'VALID' ?
                        <div className='text--sm-regular status'>
                            {`${t('Completed')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}${item.expires_date ? ' | ' + t('Expires') + ' ' + datelib.epoch_to_date_string(item.expires_date) : ''}`}
                        </div>
                        : <div className='text--sm-regular status'>{t('Incomplete')}</div>
                    }
                </div>
            </div>
        );
    }

    function render_credential(item) {
        return (
            <div key={item.individualprequalifier_id} className='prequalifier'>
                <div className='text--sm-medium'>{t('Credential')}</div>
                <div className='prequalifier_text'>
                    <div className='text--sm-regular description'>{item.short_description}</div>
                    <div className='prequalifier_credential_detail text--sm-regular'>
                        {(item.status === 'VALID' && item.additional_text !== 'Verifiable credential') ?
                            <>
                                <Button onClick={() => onClick_submitted_credential(item.additional_text, item.submitted_individual_credential_id)} className={'quaternary' + (item.credential_name ? ' text--anchor' : '')} disabled={!item.credential_name} aria-disabled={!item.credential_name && 'true'}>
                                    <span className='credential_name'>{item.credential_name}</span>
                                    {item.has_customsecurity === 'YES' && <Icon name='lock' className='icon__lock color--gray-400' alt={t('lock icon')} />}
                                </Button>
                                <div className='text--sm-regular status'>
                                    {`${t('submitted')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}`}
                                </div>
                            </>
                            : (item.status === 'VALID' && item.additional_text === 'Verifiable credential')
                            ?
                                <div className='text--sm-regular status'>
                                    {`${t('Verifiable credential submitted')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}`}
                                </div>
                            : <div className='text--sm-regular status'>{t('Incomplete')}</div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    function render_credit_credential(item) {
        return (
            <div key={item.prequalifier_id} className='prequalifier'>
            <div className='text--sm-medium'>{t('Credit-based credential')}</div>
                <div className='prequalifier_text'>
                    <div className='text--sm-regular description'>{item.short_description}</div>
                    <div className={'badge text--sm-medium' + (item.credits_obtained >= item.credits_required ? ' badge--green' : ' badge--yellow')}>
                        {`${item.credits_obtained}/${item.credits_required} ${item.credits_required === 1 ? t('credit') : t('credits')}`}
                    </div>

                    {item.submitted_credentials.map(submitted =>
                        <div key={submitted.individual_prequalifier_credential_id} className='prequalifier_credential_detail text--sm-regular'>
                            <Button onClick={() => onClick_submitted_credential(item.additional_text, submitted.submitted_individual_credential_id)} className={'quaternary' + (submitted.credential_name ? ' text--anchor' : '')} disabled={!submitted.credential_name} aria-disabled={!submitted.credential_name && 'true'}>
                                <span className='credential_name'>{submitted.credential_name}</span>
                                {submitted.has_customsecurity === 'YES' && <Icon name='lock' className='icon__lock color--gray-400 credit_credential' alt={t('lock icon')} />}
                                {`(${submitted.credit_value} ${submitted.credit_value === 1 ? t('credit') : t('credits')})`}
                            </Button>
                            <div className='text--sm-regular status'>
                                {`${submitted.status === 'EXPIRED' ? t('Expired') : ``} ${t('submitted ')} ${datelib.epoch_to_datetime_string(submitted.submitted_date, true)}`}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    function render_document(item) {
        return (
            <div key={item.individualprequalifier_id} className='prequalifier'>
                <div className='text--sm-medium'>{t('Document acknowledgement')}</div>
                <div className='prequalifier_text'>
                    <div className='text--sm-regular description'>{item.short_description}</div>
                    {item.status === 'VALID' ?
                        <div className='text--sm-regular status'>
                            {`${t('Completed')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}${item.expires_date ? ' | ' + t('Expires') + ' ' + datelib.epoch_to_date_string(item.expires_date) : ''}`}
                        </div>
                        : <div className='text--sm-regular status'>{t('Incomplete')}</div>
                    }
                </div>
            </div>
        );
    }

    function render_survey(item) {
        return (
            <div key={item.individualprequalifier_id} className='prequalifier'>
                <div className='text--sm-medium'>{t('Survey')}</div>
                <div className='prequalifier_text'>
                    <div className='text--sm-regular description'>{item.short_description}</div>
                    {item.status === 'VALID' ?
                        <div className='text--sm-regular status'>
                            {`${t('Completed')} ${datelib.epoch_to_datetime_string(item.submitted_date, true)}`}
                        </div>
                        : <div className='text--sm-regular status'>{t('Incomplete')}</div>
                    }
                </div>
            </div>
        );
    }

}