import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';
import 'i18n';
import Processing from 'components/cmp_processing';
import API from 'libs/api-lib';

import './cmp_vc_authenticator.css';

/**
 * @param {{
 *      display: boolean,
 *      title: String,
 *      instruction_line: String,
 *      open_button_text: String,
 *      entra_issuance_request: {
 *          url: String,
 *          callback_id: String
 *      },
 *      verification_code?: String,
 *      processing: Boolean,
 *      onClose: (error?: Boolean) => void
 * }} props
 */
function CMP_VC_AUTHENTICATOR({ 
        display, 
        title, 
        instruction_line, 
        open_button_text,
        entra_issuance_request, 
        verification_code,
        processing,
        onClose 
    }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    
    const [ var_modal, set_modal ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_entra_issuance_request_status, set_entra_issuance_request_status ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (var_modal) {
            let firstelement = var_modal.querySelectorAll('button:not([disabled])')[0];
            let lastelement = Array.from(var_modal.querySelectorAll('button:not([disabled])')).at(-1);

            //  set focus to first element within the modal
            firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key !== 'Tab') return;

                if (e.shiftKey && document.activeElement === firstelement) /* shift + tab */ {
                    lastelement.focus();
                    e.preventDefault();
                } else if (!e.shiftKey && document.activeElement === lastelement) /* tab */ {
                    firstelement.focus();
                    var_modal.querySelector('.modal__header').scrollIntoView();
                    e.preventDefault();
                }
            });
        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_modal(document.querySelector('#mdl_cmp_vc_authenticator'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    useEffect(() => {
        // Ping callback API for state of VC wallet issuance
        if (display && entra_issuance_request?.callback_id && var_entra_issuance_request_status !== 'issuance_error') {
            let interval = setInterval(() => {
                check_issuance_status();
            }, 5000); // Five seconds
            return () => clearInterval(interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display, entra_issuance_request?.callback_id]);


    //  async functions ------------------------------------------------------------------------------------------------
    async function check_issuance_status() {
        try {
            let result = await API_get_credential_issuance_callback();
            set_entra_issuance_request_status(result.requestStatus);
            if (result.requestStatus === 'request_retrieved') {
                // User scanned the QR
                set_processing(true);
            } else if (result.requestStatus === 'issuance_successful') {
                // Credential was added to user's wallet
                onClose();
            } else if (result.requestStatus === 'issuance_error') {
                set_processing(false);
                onClose(true);
            }
        } catch (e) {
            console.error('Failed to retrieve credential issuance status');
            console.error(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------
    function API_get_credential_issuance_callback() {
        return API.get('credentials', `/get-credential-issuance-callback/${entra_issuance_request.callback_id}`);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick() {
        window.open(entra_issuance_request.url);
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_cmp_vc_authenticator'
            dimmer={'inverted'}
            onClose={() => onClose()}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            className='cmp_vc_authenticator'
            >

            <div className='modal__header'>
                <div style={ { width: '100%' }}>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium' id='hdr_cmp_vc_authenticator'>{title}</div>
                    </div>
                </div>
            </div>

            <div className='modal__content'>
                <div className='modal__content__text text--sm-regular' style={{flexDirection: 'column'}}>
                    <div>{instruction_line}</div>
                </div>
                {entra_issuance_request?.url &&
                <QRCodeSVG className='qr_svg'
                    value={entra_issuance_request.url}
                    size={200}
                    onClick={onClick}
                />
                }
                {verification_code?.length > 0 &&
                <>
                <div className='modal__content__text text--sm-medium verificationcode_label'>{t('Verification code')}</div>
                <div className='text--md-regular verificationcode_value'>{verification_code}</div>
                </>
                }
            </div>

            <div className='modal__footer'>
                <div>
                    <Button className='primary btn_authenticator' onClick={onClick}>{open_button_text}</Button>
                    <Button className='secondary' onClick={() => onClose()}>{t('Close')}</Button>
                </div>
            </div>
            <Processing display={var_processing || processing} processingtext={t('Processing')} />
        </Modal>
    );

}

CMP_VC_AUTHENTICATOR.propTypes = {
    display: propTypes.bool.isRequired,
    title: propTypes.string,
    instruction_line: propTypes.string,
    entra_issuance_request: propTypes.object,
    verification_code:  propTypes.string,
    processing: propTypes.bool,
    onClose: propTypes.func
};

export default CMP_VC_AUTHENTICATOR;
